@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.chartContainer {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $Grey60;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 500px;
  margin: 0.5rem;

  .header {
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    flex-flow: row wrap;
    min-height: 50px;
    padding: 14px 14px 7px 14px;
    .mainHeader {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      .leftSide {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        .headerTitle {
          @include regularTitle;

          color: $Grey100;
          display: flex;
          gap: 8px;

          .headerTitleDash {
            color: $Neutral500;
            font-weight: 400;
            padding-left: 8.414px;
            padding-right: 8.414px;
          }

          .headerTitleDateRange {
            color: $Neutral500;
            font-weight: 400;
          }

          .dt-text {
            font-weight: 600;
          }
        }
      }
      .rightSide {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        flex: 1;
        justify-content: flex-end;
        & > * {
          margin-right: 0.5rem;
        }
      }
    }
    .subHeader {
      display: flex;
    }
  }

  &.enableHoverDesign {
    border-radius: 16px;
    border: 1.5px solid $Grey60;
    height: auto;
    & > .header {
      padding: 24px 14px 7px 24px;
      border-bottom: none;
      .leftSide {
        min-height: 28px;
        .headerTitle {
          @include regularTitle;
          .capitalize {
            text-transform: capitalize;
          }
        }
      }
      .rightSide {
        display: flex;
      }
      .hidden {
        display: none;
      }
    }

    & > .contents {
      padding: 11px 2px 14px 24px;
    }

    // Chart container border hover
    &:hover {
      outline: 4px solid $Brand60;
      outline-offset: -2.5px;
      .rightSide {
        display: flex;
      }
      .subHeader {
        display: flex;
      }
    }
    .chartContainerFooter {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
  // Hover1 effect for dropdown in chart container
  &:hover {
    .header {
      .cl-dropdown {
        .widget-title {
          &::after {
            background-color: transparent;
            color: $Brand50;
          }
        }
      }
    }
  }

  .toolTipTextContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
    width: calc(100% - 168px);
    font-weight: 600;
  }

  .leftSideToolTip {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: $Brand100;
  }

  .rightSideToolTip {
    color: $Brand100;
  }

  .contents {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 16px;
    width: 100%;
    > * {
      display: flex;
      flex: 1;
      height: 100%;
      width: 100%;
    }
  }
}

@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";

.reviewModal {
  &.editing {
    .modal-dialog {
      max-width: 80vw;

      &.modal-dialog-centered {
        .modal-content {
          min-width: auto;
        }
      }
    }

    .modal-content {
      min-width: auto;
    }
  }
  .modal-dialog.modal-dialog-centered {
    justify-content: center;
    .modal-content {
      border-radius: 16px;
      border: 2px solid $Brand60;
      min-width: fit-content;
      display: flex;
      .reviewModalHeader {
        padding: 24px 24px 16px;
        display: flex;
        flex-direction: row;
        column-gap: 32px;
        align-items: flex-start;
        .close {
          color: $Brand60;
        }
        .titleInfo {
          height: 60px;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          align-items: flex-start;
          .modalTitle {
            white-space: nowrap;
            height: 30px;
            padding: 2px 0px;
            @include headingRegular;
          }
          .modalID {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            height: 22px;
            padding: 2px 0px;
            align-items: center;
            .idDisplay {
              white-space: nowrap;
              @include bodyRegular;
              color: $Brand60;
            }
          }
        }
        .modalTitle {
          white-space: nowrap;
          height: 30px;
          padding: 2px 0px;
          @include headingRegular;
        }
        .titleButtons {
          display: flex;
          flex-direction: row;
          .titleButton {
            .bf-label {
              @include bodyBold;
              white-space: nowrap;
            }
            &.selected {
              .bf-label {
                color: $Brand60;
              }
              border-bottom: 3px solid $Brand60;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
            &.unselected {
              .bf-label {
                color: $Brand30;
              }
            }
          }
        }
      }
      .reviewModalBody {
        padding: 16px 24px;
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        margin: 0px;
        min-width: 509px;
        justify-content: center;
        .adPreview {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
          .mobilePreview {
            background-color: $Brand100;
            border: 1px solid $Brand100;
            border-radius: 28px;
            padding: 10px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .innerBody {
          display: flex;
          flex-direction: column;
          row-gap: 22px;
          margin: 0px;
          width: 100%;
          .headerRow {
            display: flex;
            flex-direction: row;
            height: 34px;
            column-gap: 8px;
            border-radius: 6px;
            background-color: $Brand20;
            padding: 0px 8px;
            .level {
              display: flex;
              font-family: Graphik;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: $Brand60;
              height: 100%;
              padding: 6px 0px;
              &.campaign {
                width: 100px;
              }
              &.adset {
                width: 64px;
              }
              &.ad {
                width: 27px;
              }
            }
            .name {
              display: flex;
              font-family: Graphik;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $Brand100;
              height: 100%;
              padding: 8px 8px;
            }
          }
          .fieldRow {
            display: flex;
            flex-direction: row;
            column-gap: 18px;
            min-height: 32px;
            width: 100%;
            padding: 7px 32px 7px 16px;
            .field {
              height: 100%;
              width: 176px;
              max-width: 50%;
              word-wrap: break-word;
              @include bodyBold;
            }
            .value {
              height: 100%;
              width: calc(100% - 194px);
              min-width: 50%;
              word-wrap: break-word;
              @include bodyRegular;
              opacity: 0.5;
            }
          }
        }
      }
      .reviewModalFooter {
        padding: 24px;
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        &.warning {
          padding: 16px 12px;
          background-color: $Danger100;
          @include tertiaryRegular;
          color: $Danger800;
        }
        .deleteAction {
          flex: 1 1 0;
          margin: 0px;
          .bf-icon {
            color: $Brand100;
          }
        }
        .mainActions {
          display: flex;
          flex-direction: row;
          column-gap: 16px;
          margin: 0px;
          .editButton {
            .bf-label,
            .bf-icon {
              color: $Brand60;
            }
          }
          .approveButton {
            border-color: $Success800;
            .bf-icon {
              color: $Success800;
            }
            &:hover {
              background-color: $Success900;
              border-color: $Success900;
              .bf-label,
              .bf-icon {
                color: $White;
              }
            }
          }
          .disapproveButton {
            border-color: $Danger700;
            .bf-icon {
              color: $Danger700;
            }
            &:hover {
              border-color: $Danger800;
              background-color: $Danger800;
              .bf-label,
              .bf-icon {
                color: $White;
              }
            }
          }
        }
      }
    }
  }
}

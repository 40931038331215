@import "../utils/colors.scss";

$MapLegendWidth: 220px;

.customerInsightsActions {
  color: $White;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }

  .icon-toggle-button {
    height: 32px;

    & > * {
      height: 32px;
    }
  }
}

.customerInsightsGroupActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  .customerInsightsKey {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $Brand100;
    border-radius: 4px;
    border: 1px solid $Neutral400;
    background: #FFF;
    padding: 5px 1rem;
    font-weight: 500;

    .customerInsightsKeyItem {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:first-child) {
        margin-left: 1rem;
      }

      & > * + * {
        margin-left: 5px;
      }

      .keyFilled {
        width: 17px;
        height: 17px;
      }

      .customerKey {
        background: $Neutral400;
      }

      .marketKey {
        background: $Neutral300;
      }
    }
  }
}

.customerInsightsBody {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .customerInsightsHeader {
    display: flex;
    margin-bottom: 1rem;

    & > * + * {
      margin-left: 1rem;
    }

    .headerItem {
      flex: 1;
      border-radius: 1rem;
      border: 1px solid $Neutral400;
      background: $White;
      padding: 16px 24px;

      .headerTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $Brand100;
        font-size: 20px;
        font-weight: 600;

        .tooltipContainer {
          margin-left: 6px;
        }
      }

      .headerBody {
        color: $Brand100;
      }
    }

    .emailMatchRate {
      .headerBody {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;

        .progressBarLabel {
          display: flex;
          flex-direction: column;
        }

        .progressBarContainer {
          margin-left: 1rem;
          flex: 1;
          width: 100%;

          .progressBarWrapper {
            border-radius: 9px;
            border: 1px solid $Neutral400;
            background: $Neutral200;
          }

          .progressBarCompletion {
            width: 0;
            left: 4px;
            position: relative;
            color: $Brand100;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .widgetContainer {
    overflow: hidden;

    .rightActions {
      padding: 0 1.5rem;
    }

    .headerRow {
      height: 36px;
      align-items: center;

      .headerLeft {
        .primaryTitleSize {
          color: $Brand100;
        }
      }
    }

    .widgetBody {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;

      .customerInsightsGroup {
        &.fixedHeight {
          height: 400px;
        }

        &.fullWidth {
          width: 100%;
        }

        &.incomeUnknownHeight {
          height: 580px;
        }


        &.incomeNoUnknownHeight {
          height: 531px;
        }

        &:not(.fullWidth) {
          width: calc(50% - 0.5rem);
        }

        .geoChartContainer,
        .chartContainer,
        .tableContainer {
          margin: 0;
          padding: 1.5rem;

          .title {
            color: $Brand100;
            font-size: 24px;
            font-weight: 600;
          }

          .header {
            height: fit-content;
            min-height: fit-content;
            border-bottom: none;
            padding: 2px 0;
            color: $Brand100;

            .mainHeader .leftSide .headerTitle {
              max-width: unset;
              color: $Brand100;
            }

            .subHeader {
              display: none;
            }
          }
        }

        .chartContainer,
        .tableContainer {
          height: 100%;
          border-radius: 16px;
        }

        .geoChartContainer {
          height: fit-content;

          .geoMap {
            display: flex;
            flex-direction: row;
            overflow: hidden;
            justify-content: space-between;

            .cbsaMapContainer {
              width: calc(100% - 220px - 2rem);
              max-width: 140vh;
            }

            .legend {
              margin-left: 2rem;
              width: $MapLegendWidth;

              & > * + * {
                margin-top: 1rem;
              }

              .legendKey {
                width: 100%;
                display: flex;
                flex-direction: column;

                & > * + * {
                  margin-top: 0.5rem;
                }

                .legendTitle {
                  color: $Brand100;
                  font-size: 16px;
                  font-weight: 600;
                }

                .explanation {
                  color: $Brand100;
                  font-size: 12px;
                  font-weight: 400;
                  white-space: pre-wrap;
                }

              }

              .legendItem {
                display: flex;
                flex-direction: row;
                align-items: center;

                & > * + * {
                  margin-left: 0.5rem;
                }

                .color {
                  width: 16px;
                  height: 12px;
                }

                .range {
                  color: $Brand100;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .tableContainer {
          background-color: white;
          border: 1px solid #c7cfdd;

          .BPMTable {
            height: calc(100% - 36px); // Container - header

            .tableLevels {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .customerInsightsFooter {
    width: 100%;
    margin: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .transUnionLogo {
      width: 10%;
      max-width: 200px;
    }
  }
}
@use "sass:math";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

/// Main Nav Bar
$client-logo-height: 42px;
$client-logo-padding: 16px;
$company-selector-height: 340px;
$company-selector-width: 217px;
$expanderButtonHeight: 32px;
$expanderButtonWidth: 34px;
$header-height: 32px;
$header-padding: 10px;
$icon-height: 22px;
$icon-width: 22px;
$item-height: 40px;
$company-search-icon-height: 24px;
$company-search-icon-width: 24px;
$item-width-expanded: 218px;
$main-nav-bar-bottom-padding: 24px;
$main-nav-bar-dropdown-padding: 62px;
$main-nav-bar-header-side-padding: 24px;
$main-nav-bar-side-padding: 16px;
$main-nav-bar-top-padding: 16px;
$navbar-background: $Brand100;
$page-search-height: 32px;
$page-search-icon-width: 24px;
$search-rows-width: 344px;
$sub-nav-bar-side-padding: 32px;
$sub-nav-bar-width: 263px;
$sub-navbar-background: $Brand25;
$sub-navbar-item-height: 18px;
$width-expanded: 254px;
$width: 60px;
$z-index: 10000;

* {
  font-family: Graphik !important;
}

.overlayTriggerBackground {
  background-color: rgba(0, 0, 0, 0.5);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

// Scroll Bar
::-webkit-scrollbar {
  border-radius: 8px !important;
  width: 6px !important;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
}
// /* Handle */
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 8px !important;
  color: #cbd2e1 !important;
  height: 72.5% !important;
}

.bpm-navbar-overlay {
  @include fullBox();
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: $z-index;
}

.solid-nav-bar-container {
  align-items: center;
  display: flex;
  height: 17px;
  justify-content: center;
  vertical-align: middle;
  padding: 8px 16px;
  width: 100%;
  hr.solid-nav-bar {
    border-top: 1px solid $Brand0;
    opacity: 0.5;
    width: 100%;
  }
  hr.solid-sub-nav-bar {
    border-top: 1px solid #000000;
    opacity: 0.5;
    width: 100%;
  }
}

.solid-nav-bar-container.sub {
  padding: 40px 16px 8px 16px;
}

.navBarHeader {
  align-items: center;
  color: $Brand0;
  cursor: default;
  display: flex;
  font-family: Graphik !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important ;
  height: $header-height;
  line-height: normal;
  opacity: 0.5;
  padding: 8px $main-nav-bar-header-side-padding;
  vertical-align: middle;
  width: $width-expanded;
}

.subNavBarHeader {
  align-items: center;
  color: $Brand100;
  cursor: default;
  display: flex;
  font-family: Graphik !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: $header-height;
  line-height: normal;
  padding: 8px $sub-nav-bar-side-padding;
  vertical-align: middle;
  width: $sub-nav-bar-width;
}

.bpm-navbar {
  height: 100%;
  position: relative;

  &.Collapsed {
    width: $width;
  }
  &.Expanded {
    width: $width-expanded;
  }

  .bpm-navbar-inner-container.Expanded::-webkit-scrollbar {
    border-radius: 0px !important;
    width: 0px !important;
  }

  .bpm-navbar-inner-container {
    align-items: center;
    background-color: $navbar-background;
    bottom: 0;
    box-shadow: 4px 0px 5px -4px $Brand100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: width 0.12s ease;
    user-select: none;
    width: 100%;
    z-index: $z-index + 2;
    padding: $main-nav-bar-top-padding 0px $main-nav-bar-bottom-padding 0px;
    .bpmNavBarItemContainer {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      // Client Logo and Expander
      .mainNavBarExpander {
        .mainNavBarExpanderBlock {
          align-items: center;
          color: $Brand0;
          cursor: pointer;
          display: flex;
          height: $item-height;
          justify-content: center;
          padding: 0px $main-nav-bar-side-padding 0px $main-nav-bar-side-padding;
          width: $width;
          * {
            color: $Brand0;
            height: $icon-height;
            width: $icon-width;
          }
        }
        .mainNavBarExpanderBlock:hover {
          background-color: $Brand60;
        }
        .mainNavBarExpanderBlock:active {
          background-color: $Brand25 !important;
          * {
            color: $Brand100;
          }
        }
        .logoDropdownContainer {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-bottom: 6px;
          .logoContainer {
            align-items: center;
            display: flex;
            height: $client-logo-height;
            justify-content: center;
            overflow: hidden;
            img {
              height: $client-logo-height;
              width: $client-logo-height;
            }
            .noClientIcon {
              color: $Brand0;
              height: 18px;
              width: 18px;
            }
            .noLogoText {
              align-items: center;
              color: $Brand0;
              display: flex;
              height: $client-logo-height;
              vertical-align: middle;
              width: $client-logo-height;
            }
          }
        }
      }
      .mainNavBarExpander.Expanded {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0px 16px 16px;
        width: 100%;
        .mainNavBarExpanderBlock {
          height: $client-logo-height;
        }
        .logoDropdownContainer {
          padding-bottom: 0px;
          .noClient {
            align-items: center;
            color: $Brand0;
            display: flex;
            font-family: Graphik !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            height: 42px;
            justify-content: center;
            line-height: normal;
            padding: 3px 8px 3px 12px;
            text-align: left;
            text-wrap: balance;
            vertical-align: middle;
            width: 62px;
          }
          .logoContainer {
            padding-left: 12px;
            padding-right: 8px;
            .noLogoText {
              color: $Brand0;
              height: $client-logo-height;
              margin-left: -12px;
              max-width: 142px;
              width: fit-content;
            }
          }
          .companySelectArrowContainer {
            color: $Brand0;
            padding-right: 6px;
            .companySelectArrow {
              color: $Brand0;
              height: 18px;
              width: 18px;
            }
          }
        }
        .logoDropdownContainer:hover {
          color: $Brand40 !important;
          & > * {
            color: $Brand40 !important;
          }
          .noLogoText {
            color: $Brand40 !important;
          }
          .companySelectArrowContainer {
            * {
              color: $Brand40 !important;
            }
          }
        }
      }

      // Components
      .mainNavBarItem {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: $item-height;
        justify-content: space-between;
        width: $width;
        * {
          height: $item-height;
        }
        .mainNavBarItemIcon {
          align-items: center;
          color: $Brand0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: $width !important;
          * {
            height: $icon-height;
            width: $icon-width;
          }
        }
      }

      .mainNavBarItem.Expanded {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: $main-nav-bar-side-padding;
        padding-right: $main-nav-bar-side-padding;
        width: $width-expanded;
        * {
          height: $item-height;
        }
        .mainNavBarItemIconTitle {
          align-items: center;
          border-radius: 6px;
          display: flex;
          height: 32px;
          .mainNavBarItemIcon {
            align-items: center;
            color: $Brand0;
            display: flex;
            flex-direction: row;
            height: 32px;
            justify-content: center;
            vertical-align: middle;
            width: $icon-width + 24px !important;
            * {
              height: $icon-height;
              width: $icon-width;
            }
          }
          .mainNavBarItemTitle {
            align-items: center;
            color: $Brand0;
            display: flex;
            font-family: Graphik !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: 32px;
            justify-content: center;
            line-height: normal;
            padding: 7px 16px 7px 0px;
            vertical-align: middle;
            max-width: $item-width-expanded - $icon-width - 20px;
          }
        }
        .mainNavBarItemIconTitle.hasExpander:hover {
          background-color: #390094 !important;
          * {
            color: #ffffff !important;
          }
        }
        .mainNavBarItemTitle.mainItemSelected {
          color: #000000;
        }
        .mainNavBarItemExpanderDropdown {
          align-items: center;
          color: $Brand0;
          display: flex;
          height: 32px;
          justify-content: center;
          padding: 0px 6px;
          width: 34px;
          * {
            height: $icon-height !important;
            width: $icon-width !important;
          }
        }
        .mainNavBarItemExpander {
          align-items: center;
          color: $Brand0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 32px;
          .mainNavBarItemExpanderBlock {
            align-items: center;
            border-radius: 6px;
            color: $Brand0;
            cursor: pointer;
            display: flex;
            height: $expanderButtonHeight;
            justify-content: center;
            vertical-align: middle;
            width: $expanderButtonWidth;
            * {
              height: $icon-height;
              width: $icon-width;
            }
          }
          .mainNavBarItemExpanderBlock:hover {
            background-color: $Brand80;
          }

          .mainNavBarItemExpanderBlock.mainItemSelected {
            background-color: $Brand30;
          }
        }
      }
      .mainNavBarItem.Expanded.mainItemSelected {
        background-color: $Brand25;
      }
      .mainNavBarItem.Expanded.mainItemDropdownSelected {
        background-color: $Brand60;
      }
      .mainNavBarItem:hover:not(.mainItemSelected) {
        background-color: $Brand60;
        .mainNavBarItemIconTitle.hasExpander {
          background-color: $Brand70;
        }
        .mainNavBarItemExpanderBlock {
          background-color: $Brand70;
        }
      }
      .mainNavBarItem.activePage,
      .mainNavBarItem:active:not(.hasExpander.Expanded) {
        background-color: $Brand25 !important;
        color: $Brand100 !important;
        .mainNavBarItemTitle {
          color: $Brand100;
        }
        .mainNavBarItemIcon {
          color: $Brand100;
        }
        .mainNavBarItemExpanderDropdown {
          color: $Brand100;
        }
      }
      .mainItemSelected {
        & > * {
          color: $Brand100;
        }
      }
      .mainNavBarItemDropdownRows {
        align-items: flex-start;
        background-color: $Brand60;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 8px;
        .mainNavBarItemDropdownRow {
          align-items: center;
          background-color: $Brand60;
          color: $Brand0;
          display: flex;
          flex-direction: row;
          font-family: Graphik !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          height: 30px;
          padding-bottom: 6px;
          padding-left: $main-nav-bar-dropdown-padding;
          padding-top: 6px;
          vertical-align: middle;
          width: $width-expanded;
        }
        .mainNavBarItemDropdownRow:hover {
          background-color: $Brand70;
        }
        .mainNavBarItemDropdownRow.activeSubPage,
        *:active {
          background-color: $Brand25 !important;
          color: $Brand100;
        }
      }

      // Search Bar
      .navSearchContainer {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex: 1;
        height: $item-height;
        justify-content: center;
        padding: 0px 16px;
        position: relative;
        width: 100%;
        .pageSearchIconContainer {
          align-items: center;
          color: $Brand0;
          cursor: text;
          display: flex;
          flex-direction: row;
          height: $item-height;
          justify-content: center;
          width: $page-search-icon-width;
          z-index: $z-index + 3;
          * {
            color: $Brand0;
            height: $page-search-icon-width;
            width: $page-search-icon-width;
            z-index: $z-index + 3;
          }
        }
      }
      .navSearchContainer:hover {
        background-color: $Brand60;
      }
      .navSearchContainer:active:not(.Expanded) {
        background-color: $Brand25 !important;
        * {
          color: $Brand100;
        }
      }
      .navSearchContainer.Expanded {
        padding-bottom: 6px;
        height: $page-search-height;
        .pageSearchInput {
          background-color: $Brand80;
          border-color: transparent;
          border-radius: 6px;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          height: $page-search-height + 2px;
          line-height: normal;
          margin-left: -20px - $page-search-icon-width;
          padding: 7px 8px 7px 20px + $page-search-icon-width;
          vertical-align: middle;
          width: $item-width-expanded;
          z-index: $z-index + 2;
        }
        .pageSearchInput::placeholder {
          color: rgba(255, 255, 255, 0.5) !important;
        }
        .pageSearchInput:focus {
          border: 1px solid var(--White, #fff) !important;
          color: #ffffff !important;
          font-family: Graphik !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .pageSearchIconContainer {
          height: $page-search-height;
          margin-left: 12px;
          margin-right: 8px;
        }
      }
      .navSearchContainer.Expanded:hover {
        background-color: transparent;
      }
    }
    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      &.footer {
        img {
          width: 40.342px;
          height: 40.342px;
          flex-shrink: 0;
        }
      }
      &.Expanded {
        img {
          height: 51.745px;
          width: 196.636px;
        }
      }
    }
  }

  .bpm-sub-navbar-inner-container {
    align-items: center;
    background-color: $sub-navbar-background;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: $width-expanded;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    transition: width 0.12s ease;
    padding-top: 16px;
    padding-bottom: 24px;
    width: $sub-nav-bar-width;
    z-index: $z-index + 1;
    .bpmNavBarItemContainer {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: $sub-nav-bar-width;
      .closeSubNavBar {
        align-items: center;
        cursor: default;
        display: flex;
        height: 32px;
        justify-content: flex-end;
        margin-bottom: 8px;
        width: $sub-nav-bar-width;
        .closeSubNavBarTitle {
          color: var(--brand-brand-50, #8254ff);
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          height: 18px;
          line-height: normal;
          padding: 0px 32px;
          width: $sub-nav-bar-width - 54px;
        }
        .closeSubNavBarIcon {
          cursor: pointer;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-left: 16px;
          padding-right: 16px;
          height: 32px;
          width: 54px;
          * {
            color: $Brand100;
            height: 22px;
            width: 22px;
          }
        }
      }
      .subNavBarItem {
        align-items: center;
        color: $Brand100;
        display: flex;
        flex-direction: row;
        font-family: Graphik !important;
        height: fit-content;
        justify-content: flex-start;
        min-height: $sub-navbar-item-height;
        padding: 8px $sub-nav-bar-side-padding;
        width: $sub-nav-bar-width;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .subNavBarItem:hover {
        background-color: $Brand20;
      }
      .subNavBarItem:active {
        background-color: $Brand0;
      }
    }
    .bpm-sub-navbar-shortcuts-container {
      align-items: center;
      border-radius: 6px;
      color: $Brand100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: $sub-nav-bar-width;
      .shortcuts {
        align-items: center;
        display: flex;
        height: $item-height;
        justify-content: flex-start;
        padding-left: 28px;
        width: $sub-nav-bar-width;
        .svg {
          height: $icon-height;
          min-width: $icon-width;
        }
        .shortcutsLabel {
          font-family: Graphik !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 8px 0% 8px 12px;
        }
      }
    }
    .bpm-sub-navbar-shortcuts-container:hover {
      * {
        cursor: pointer;
      }
    }
  }
}

.navItemTooltip {
  left: 40px !important;
  transform: unset !important;
  z-index: $z-index + 2;
}
.companySelector {
  align-items: center;
  background-color: $Brand0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  max-height: $company-selector-height;
  outline: 3px solid #8254ff;
  overflow: visible;
  padding: 8px 0px;
  width: $company-selector-width;
  z-index: $z-index + 3;
  .companySelectorTitle {
    color: $Brand100;
    cursor: default;
    font-family: Graphik !important;
    font-size: 16px;
    font-size: medium;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    height: 32px;
    line-height: normal;
    padding: 7px 16px;
    text-align: left;
    width: $item-width-expanded;
  }
  .companySelectorSearchBar {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    padding: 4px 12px 8px 12px;
    width: $company-selector-width;
    .companySearchInput {
      background-color: #f1f3f9;
      border-radius: 6px;
      border: 0px;
      color: #94a0b8;
      font-family: Graphik !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      height: 32px;
      line-height: normal;
      margin-left: -$company-search-icon-width - 8px;
      padding-left: $company-search-icon-width + 8px;
      padding-right: 8px;
      width: 193px;
    }
    .companySearchInput::placeholder {
      color: #94a0b8;
    }
    .pageSearchIcon {
      align-items: center;
      cursor: text;
      display: flex;
      justify-content: center;
      z-index: $z-index + 1;
      vertical-align: middle;
      height: 32px;
      * {
        margin: 0 4px;
        color: #94a0b8;
        height: $company-search-icon-height;
        width: $company-search-icon-width;
      }
    }
  }
  .companySelectorRows {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: flex-start;
    max-height: $company-selector-height - $icon-height;
    overflow-x: hidden;
    overflow-y: auto;
    width: $item-width-expanded;
    .companySelectorRow {
      align-items: center;
      color: $Brand100;
      display: flex;
      font-weight: 500;
      height: 32px;
      justify-content: flex-start;
      max-height: fit-content;
      overflow-wrap: break-word;
      padding: 7px 16px;
      text-wrap: balance;
      vertical-align: middle;
      width: $company-selector-width;
      word-wrap: break-word;
      * {
        color: $Brand100;
        font-family: Graphik !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-height: 18px;
      }
    }
    :hover {
      background-color: #8254ff;
      color: $Brand0;
    }
  }
  .cancelContainer {
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: flex-end;
    padding: 16px 16px 8px 52px;
    width: $item-width-expanded;
    .cancelButton {
      border-radius: 6px;
      border: 1px solid #cbd2e1;
      color: $Brand100;
      cursor: pointer;
      font-family: Graphik !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6.5px 16px;
    }
  }
}

.searchResults {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  left: ($width-expanded - $item-width-expanded)/2;
  min-width: $search-rows-width;
  overflow: visible;
  position: absolute;
  top: $main-nav-bar-top-padding + $client-logo-height + $client-logo-padding + $page-search-height;
  width: fit-content;
  z-index: $z-index + 100;
  .searchResultsRows {
    align-items: flex-start;
    background-color: $Brand0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 244px;
    min-width: $search-rows-width;
    outline: 2px solid #8254ff;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px 0px;
    width: fit-content;
    z-index: $z-index + 1;
    .searchResultsRow {
      color: $Brand100;
      display: flex;
      flex-direction: column;
      font-family: Graphik !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      justify-content: center;
      line-height: normal;
      max-height: fit-content;
      min-height: 18px;
      min-width: $search-rows-width;
      padding: 7px 16px;
      text-wrap: wrap;
      vertical-align: middle;
      width: fit-content;
    }
    .searchResultsRow:hover {
      background-color: $Brand80;
      color: $Brand0;
    }
  }
}
